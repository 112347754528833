@tailwind base;


@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  p {
    @apply text-sm md:text-base;
  }
  .text-sm {
    font-size: .85rem !important;
  }
  .text-xxs {
    font-size: .6rem !important;
  }
}

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2c2c2c;
  */
  /* height: 100vh; */
}

html, body {
  overscroll-behavior: none;
  height: 100dvh !important;
  @apply bg-gray-900;
}

p.is-editor-empty:first-child::before {
  /* @apply text-input-value-light text-sm leading-5; */
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.dynamicHeight {
  height: 100dvh !important;
}

#root {
  height: 100dvh !important;
}

.modal {
  @apply bg-gray-700 p-0 border border-gray-600 rounded-md text-white !important;
}


@media (max-height: 800px) {
  .position.jsx-3957257948 {
    /* vertical-align: top !important; */
    margin-top: 6rem !important;
  }
}


.backdrop {
  background-color: rgba(11, 11, 11, 0.85) !important;
}

/* Input */

.sbui-input-container {
  @apply relative;
}

.sbui-input {
  @apply block box-border pl-3 pr-3 py-2 w-full rounded-md shadow-sm text-sm border border-solid transition-all;
  @apply bg-white text-input-value-light border-input-border-light;
  @apply focus:ring-input-border-focus-light focus:border-input-border-focus-light focus:outline-none;

  @apply dark:bg-transparent dark:text-input-value-dark dark:border-input-border-dark;
  @apply dark:focus:border-input-border-focus-dark dark:focus:ring-input-border-focus-dark;

  /* box-shadow: 0 0 0 2px rgba(255, 255, 255, 0); */
  font-family: inherit;
  transition: box-shadow 0.3s ease-in-out;
}

.sbui-input:focus {
  box-shadow: 0 0 0 2px rgba(62, 207, 142, 0.1);
}

.sbui-input--error {
  @apply border-red-500 dark:border-red-500;
}

.sbui-input--borderless {
  @apply border-transparent shadow-none;
}

.sbui-input-actions-container {
  @apply absolute inset-y-0 right-0 pl-3 pr-1 flex items-center;
}

/*
  Input sizes
*/

.sbui-input--tiny {
  @apply px-2.5 py-1.5 text-xs;
}
.sbui-input--small {
  @apply px-3 py-2 text-sm leading-4;
}
.sbui-input--medium {
  @apply px-4 py-2 text-sm;
}
.sbui-input--large {
  @apply px-4 py-2 text-base;
}
.sbui-input--xlarge {
  @apply px-6 py-3 text-base;
}

/*
  Input icon
*/

.sbui-input--with-icon {
  @apply pl-10;
}